.logo {
   position: absolute;
   width: 70px; 
   height: auto; 
   margin-left: 5px; 
   margin-top: 3px;
   border-radius: 10px;
 }

 /* Media query for screens with a maximum width of 600 pixels */
@media (max-width: 600px) {
   .logo {
     display: none; /* Hide the logo on smaller screens */
   }
 }