body {
  background: linear-gradient(to bottom, #B0AFB4, #A68360);
}

.parallax-container {
  position: relative;
  display: grid;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  place-items: center;
}

.parallax-text {
  position: relative;
  font-weight: bold;
  color: #f0f0f1;
  z-index: 10;
  margin-top: -200px;
  padding: 10px;
  text-align: center;
}

.parallax-image-full {
  position: absolute;
  inset: 0px;
  z-index: 0;
}

.parallax-image-bottom {
  position: absolute;
  inset: 0px;
  z-index: 20;
}

/* Media query for tablet screens */
@media (max-width: 768px) {
  .parallax-text {
    font-size: 80px; /* Adjust font size for tablets */
  }
}

/* Media query for mobile screens */
@media (max-width: 480px) {
  .parallax-text {
    font-size: 60px; /* Adjust font size for mobile */
  }
}


.process-step {
  position: relative;
  width: 100%;
  height: auto; /* 100% of the viewport height */
}

.process-step p {
  background-color: #f0f0f1;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  color: black;
  font-weight: 400;
  margin: 15px;
}

.arrow {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
}


.process-step h1 {
  display: flex;
  justify-content: center;
  font-weight: bold;
  color: white;
  font-size: 100px;
}

/* Media query for tablet screens */
@media (max-width: 768px) {
  .process-step h1 {
    font-size: 60px; /* Adjust font size for tablets */
  }
}

/* Media query for mobile screens */
@media (max-width: 480px) {
  .process-step h1{
    font-size: 50px; /* Adjust font size for mobile */
  }
}