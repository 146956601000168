body {
  background-color: black;
  color: white;
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

.bartender {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 10;
}

.bar {
  height: 9.2%;
  width: 100%;
  background-color: white;
  animation-name: tend;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  transform: translateX(-100%);
  animation-play-state: paused;
  z-index: 10;
}

@keyframes tend {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

/* Reset default margin and padding on the list */
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Style the navigation bar */
nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0);
}

/* Style the navigation list */
nav ul {
  display: flex; /* Display the list items horizontally */
  justify-content: space-around; /* Distribute items evenly */
  padding: 10px;
  margin: 0;
}

/* Style the list items */
nav li {
  padding: 10px;
}

/* Style the links */
nav a {
  color: white; /* Link text color */
  text-decoration: none; /* Remove underline */
  padding: 10px;
}

/* Change link color on hover */
nav a:hover {
  color: black;
  background: #f0f0f1; /* Base grey color */
  border-radius: 15px;
}

.motion {
  /* background-color: #34D399; */
  border-radius: 50%;
  position: absolute;
  transform: translateY(-50%);
  transform: translateX(-50%);
}

.dot-container {
  display: grid;
  margin-top: 25px;
  grid-template-columns: repeat(12, 25px); /* Change the number to define the number of columns and width */
  gap: 25px; /* Adjust the gap between circles */
  justify-content: center; /* Center the grid horizontally */
}

/* CSS for styling the SplitScreen component */

.split-screen {
  display: flex;
  height: 100vh;
}

.left-half {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 20px;
}

.content {
  text-align: center;
}

.right-half {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.photo {
  margin-top: 56px;
  /* background-size: cover; */
  background-position: center;
  width: 100%;
  height: 100%;
}

.button-container {
  margin-top: 20px;
}

.action-button {
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
}

.action-button:hover {
  background-color: #2779bd;
}

.filler {
  color: blue;
  padding: 50;
}