.cta-buttons {
  display: flex;
  width: 20%;
  bottom: 0;
  left: 0;
  display: flex;
  padding: 10px; 
  justify-content: space-between; 
  position: absolute;
  z-index: 10;
}

.title {
  position: absolute;
  padding: 10px;
  text-align: left;
  font-size: 85px;
  font-weight: bold;
  z-index: 10;
}

/* For Mobile */
@media screen and (max-width: 540px) {
  .title {
    font-size: 30px;
  }
}

/* For Tablets */
@media screen and (min-width: 540px) and (max-width: 780px) {
  .title {
      width: 50px;
  }
}

/* CSS */
.button-68 {
  appearance: none;
  backface-visibility: hidden;
  background-color: white;
  border-radius: 15px;
  border-style: none;
  box-shadow: rgba(39, 174, 96, .15) 0 4px 9px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: Inter,-apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  padding: 13px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.button-69 {
  color: white;
  appearance: none;
  backface-visibility: hidden;
  background-color: transparent;
  border-radius: 15px;
  border-style: none;
  cursor: pointer;
  display: inline-block;
  font-family: Inter,-apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  padding: 13px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.button-68:hover, .button-69:hover{
  background-color: #D3D3D3;
  opacity: 1;
  transform: translateY(0);
  transition-duration: .35s;
}

.button-68:active, .button-69:active {
  transform: translateY(2px);
  transition-duration: .35s;
}

.button-68:hover, .button-69:hover {
  box-shadow: rgba(39, 174, 96, .2) 0 6px 12px;
}