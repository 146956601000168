body {
   background-color: black;
   color: white;
 }
 
 .bartender-com {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   pointer-events: none;
 }
 
 .bar-com {
   height: 9.2%;
   width: 100%;
   background-color: white;
   animation-name: tend-com;
   animation-duration: 0.5s;
   animation-timing-function: ease-in-out;
   animation-fill-mode: forwards;
   transform: translateX(0%);
 }
 
 @keyframes tend-com {
   0% {
     transform: translateX(0%);
   }
   100% {
     transform: translateX(100%);
   }
 }
