.body {
  height: 100vh;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.main {
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
}

.item {
  width: 200px;
  height: 300px;
  list-style-type: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  box-shadow: 0 20px 30px rgba(255, 255, 255, 0.3) inset;
  transition: transform 0.1s, left 0.75s, top 0.75s, width 0.75s, height 0.75s;

  &:nth-child(1),
  &:nth-child(2) {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: none;
    border-radius: 0;
    box-shadow: none;
    opacity: 1;
  }

  &:nth-child(3) {
    left: 50%;
  }
  &:nth-child(4) {
    left: calc(50% + 220px);
  }
  &:nth-child(5) {
    left: calc(50% + 440px);
  }
  &:nth-child(6) {
    left: calc(50% + 660px);
    opacity: 0;
  }
}

a {
  text-decoration: none; /* Removes underline */
  color: inherit; /* Keeps the original color, preventing a change on click */
}

a:visited {
  color: inherit; /* Keeps the original color for visited links */
}

.content {
  width: min(30vw, 400px);
  position: absolute;
  top: 20rem;
  left: 2rem;
  transform: translateY(-50%);
  color: white;
  opacity: 0;
  display: none;

  & h2 {
    text-transform: uppercase;
  }

  & .description {
    line-height: 1.7;
    margin: 1rem 0 1.5rem;
    font-size: .9rem;
    font-weight: semibold;
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.1);
    border: 2px solid white;
    padding: 0.75rem;
  }

  & button {
    width: fit-content;
    background-color: rgba(0, 0, 0, 0.1);
    color: white;
    border: 2px solid white;
    padding: 0.75rem;
    cursor: pointer;
    border-radius: 15px;
  }
  & button:hover {
   background-color: rgba(255, 255, 255, 0.4);
   font-weight: bold; 
  }
}

.item:nth-of-type(2) .content {
  display: block;
  animation: show 0.75s ease-in-out 0.3s forwards;
}

@keyframes show {
  0% {
    filter: blur(5px);
    transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.nav {
  position: absolute;
  display: flex;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  user-select: none;
  background-color: transparent;
}

& .btn {
  background-color: rgba(255, 255, 255, 0.5);
  color: rgba(0, 0, 0, 0.7);
  border: 2px solid rgba(0, 0, 0, 0.5);
  margin: 0 0.25rem;
  padding: 0.75rem;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.5rem;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  @media (width > 650px) and (width < 900px) {
    .content {
      & .title {
        font-size: 1rem;
      }
      & .description {
        font-size: 0.7rem;
      }
      & button {
        font-size: 0.7rem;
      }
    }
    .item {
      width: 160px;
      height: 270px;

      &:nth-child(3) {
        left: 50%;
      }
      &:nth-child(4) {
        left: calc(50% + 170px);
      }
      &:nth-child(5) {
        left: calc(50% + 340px);
      }
      &:nth-child(6) {
        left: calc(50% + 510px);
        opacity: 0;
      }
    }
  }
  @media (width < 650px) {
    .content {
      & .title {
        font-size: 0.9rem;
      }
      & .description {
        font-size: 0.65rem;
      }
      & button {
        font-size: 0.7rem;
      }
    }
    .item {
      width: 130px;
      height: 220px;

      &:nth-child(3) {
        left: 50%;
      }
      &:nth-child(4) {
        left: calc(50% + 170px);
      }
      &:nth-child(5) {
        left: calc(50% + 280px);
      }
      &:nth-child(6) {
        left: calc(50% + 4200px);
        opacity: 0;
      }
    }
  }
}
