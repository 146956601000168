body {
  margin: 0;
  padding: 0;
}

.missionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  background-color: white;
  color: black;
}

.processContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: white;
  color: black;
}

.exampleContainer {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: auto;
  background-color: white;
}

.stepsContainer {
  background: black;
  display: flex;
  width: 20%;
  height: 35vh;
  /* objectfit: "contain"; */
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  border-style: solid;
  border-width: 8px;
}

.stepsContainer span {
  position: absolute;
  color: #f66465;
  font-size: 35px;
  font-weight: bold;
  z-index: 10;
  border-radius: 15px;
  background-color: transparent;
  backdrop-filter: blur(10px);
  padding: 5px;
}

.stepsContainer:hover {
  transform: translateY(-10px);
  transition-duration: .35s;
}

.missionStatement {
  padding: 20px;
  text-align: center;
  font-size: 55px;
  font-weight: semi-bold;
}

.processWords {
  width: 50%;
  height: auto;
  text-align: left;
  margin: 100px;
}
.processImage {
  width: 50%;
  height: auto;
  text-align: left;
}

.exampleImage {
  width: 100%;
  height: 100%;
}

.exampleHeader {
  display: grid;
  width: 100%;
  height: auto;
  background-color: white;
  color: black;
  justify-content: center;
}

.exampleHeader span {
  text-align: center;
}

.processTitle {
  font-weight: bold;
  font-size: 60px;
  border-radius: 15px;
  padding: 10px;
  background-color: #d2d2d2;
}

h2 {
  font-size: 40px;
}

p.highlight {
  color: #f66465; /* Set the color to your desired highlight color */
}
