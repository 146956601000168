/* Footer.css */
.footer {
   background-color: black;
   color: #fff;
   padding: 20px;
   text-align: center;
   /* position: fixed; */
   bottom: 0;
   width: 100%;
 }
 
 .footer-content {
   max-width: 1200px;
   margin: 0 auto;
 }
 